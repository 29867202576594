/* eslint-disable */
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'

import i18next from './src/i18n/i18n-config'

/* Provide app with i18n */
export const wrapRootElement = ({element}) => {
	return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>
}
