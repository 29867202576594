import {createContext} from 'react'

import {DEFAULT_LANGUAGE} from '@i18n/i18n-config'

interface LanguageContextProps {
	activeLanguage: string
	setActiveLanguage: (language: string) => void
}
const InterfaceLanguage = createContext<LanguageContextProps>({
	activeLanguage: DEFAULT_LANGUAGE,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setActiveLanguage: () => {}
})

export default InterfaceLanguage
