import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside Contact functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		contact: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			columnGap: theme.spacing(15 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(30 / 8)
			}
		},
		title: {
			gridRow: '1',
			justifySelf: 'center',
			width: 'fit-content',
			color: theme.palette.text.primary,

			// Mobile
			gridColumn: '1 / span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '2 / span 10'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '4 / span 6'
			}
		},
		body: {
			gridRow: '2',
			justifySelf: 'center',
			width: 'fit-content',
			textAlign: 'center',
			margin: theme.spacing(16 / 8, 0, 40 / 8),
			color: theme.palette.text.secondary,
			fontSize: 18,
			letterSpacing: '-0.25px',
			lineHeight: '28px',

			// Mobile
			gridColumn: '1 / span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '2 / span 10'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '4 / span 6'
			}
		},
		mailButton: {
			gridRow: '3',

			// Mobile
			gridColumn: '3 / span 8',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '3 / span 4'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '5 / span 2'
			}
		},
		callButton: {
			borderRadius: 28,
			padding: theme.spacing(16 / 8),
			textTransform: 'none',

			// Mobile
			gridRow: '4',
			gridColumn: '3 / span 8',
			marginTop: theme.spacing(30 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridRow: '3',
				gridColumn: '7 / span 4',
				marginTop: 0
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridRow: '3',
				gridColumn: '7 / span 2',
				marginTop: 0
			}
		}
	}))()

export default useStyles
