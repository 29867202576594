import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSSR, useTranslation} from 'react-i18next'

import allLocales from '@i18n/locales/index'
import {changeLanguage, getLangFromBrowser} from '@utils/i18n'
import {DEFAULT_LANGUAGE} from '@i18n/i18n-config'
import {LanguageContext} from '@utils/context'

/**
 * I18nWrapper
 */
interface I18nWrapperProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	languageFromPath?: string
}

export const I18nWrapper: React.FC<I18nWrapperProps> = ({
	children,
	languageFromPath
}) => {
	const {i18n} = useTranslation(undefined, {useSuspense: false})
	const isBrowser = typeof process !== `undefined` && process.browser === true
	const [activeLanguage, setActiveLanguage] = useState<string>(DEFAULT_LANGUAGE)

	useSSR(allLocales, languageFromPath || DEFAULT_LANGUAGE)

	useEffect(() => {
		setActiveLanguage(languageFromPath || getLangFromBrowser())
	}, [languageFromPath])

	// The useEffect hook isn't triggered on SSR, so we do it like this..
	// Looking for a better solution
	if (languageFromPath && !isBrowser) {
		changeLanguage(i18n, languageFromPath)
	}

	return (
		<LanguageContext.Provider value={{activeLanguage, setActiveLanguage}}>
			{children}
		</LanguageContext.Provider>
	)
}

I18nWrapper.propTypes = {
	children: PropTypes.node,
	languageFromPath: PropTypes.string
}

export default I18nWrapper
