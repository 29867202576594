import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Divider} from '@material-ui/core'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * Margin
 */
interface MarginProps extends CommonProps {
	top?: number
	right?: number
	bottom?: number
	left?: number
}

const Margin: React.FC<MarginProps> = ({id, className, top, right, bottom, left}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles({top, right, bottom, left})

	return (
		<Divider
			id={id}
			className={classNames(classes.root, className && className)}
		/>
	)
}

Margin.propTypes = {
	top: PropTypes.number,
	right: PropTypes.number,
	bottom: PropTypes.number,
	left: PropTypes.number,
	id: PropTypes.string,
	className: PropTypes.string
}

export default Margin
