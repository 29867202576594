import {PropTypes} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside GlowButton functional component
 */

export interface StyleProps {
	color?: PropTypes.Color
}

export const muiColorMap = (theme: Theme) =>
	new Map<PropTypes.Color, string>([
		['default', theme.palette.grey[300]],
		['inherit', 'inherit'],
		['primary', theme.palette.primary.main],
		['secondary', theme.palette.secondary.main]
	])

const useStyles: Styling.US<StyleProps> = ({color}) =>
	makeStyles((theme: Theme) => ({
		glowButton: {
			position: 'relative',
			borderRadius: 28,
			padding: theme.spacing(16 / 8),
			textTransform: 'none',

			'&::after': {
				content: '""',
				position: 'absolute',
				zIndex: '-1',
				bottom: 0,
				width: '80%',
				height: '80%',
				borderRadius: 28,
				backgroundColor: muiColorMap(theme).get(color),
				filter: 'blur(10px)'
			}
		}
	}))()

export default useStyles
