import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box, Button, Typography} from '@material-ui/core'

import {CommonProps} from '@utils/types'
import {
	BackdropTypographyComponent,
	GlowButtonComponent
} from '@components/index'

import useStyles from './style.hook'

/**
 * Contact
 */
const Contact: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {
		title,
		titleBackdrop,
		body,
		mailButtonText,
		mailButtonEmail,
		callButtonText,
		callButtonPhoneNumber
	} = useStaticQuery(graphql`
		query contentfulContactComponentQuery {
			contentfulContactComponent {
				title
				titleBackdrop
				body {
					body
				}
				mailButtonText
				mailButtonEmail
				callButtonText
				callButtonPhoneNumber
			}
		}
	`).contentfulContactComponent

	const mailButtonOnClick = () => {
		location.href = `mailto:${mailButtonEmail}`
	}

	const callButtonOnClick = () => {
		location.href = `tel:${callButtonPhoneNumber}`
	}

	return (
		<Box
			id={id}
			className={classNames(classes.contact, className && className)}
		>
			<BackdropTypographyComponent
				className={classes.title}
				backdropText={titleBackdrop}
				propsToDelegate={{variant: 'h2'}}
			>
				{title}
			</BackdropTypographyComponent>

			<Typography className={classes.body} variant="body1">
				{body.body}
			</Typography>

			<GlowButtonComponent
				className={classes.mailButton}
				variant="contained"
				color="primary"
				onClick={mailButtonOnClick}
			>
				{mailButtonText}
			</GlowButtonComponent>
			<Button
				className={classes.callButton}
				variant="outlined"
				color="secondary"
				onClick={callButtonOnClick}
			>
				{callButtonText}
			</Button>
		</Box>
	)
}

Contact.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default Contact
