import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import {Box, Typography} from '@material-ui/core'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * Footer
 */
const Footer: React.FC<CommonProps> = ({children}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {logo, footerCopyright, footerInfoSection} = useStaticQuery(graphql`
		query contentfulFooterComponentQuery {
			contentfulFooterComponent {
				logo {
					file {
						url
					}
				}
				footerCopyright
				footerInfoSection {
					title
					line1
					line1Url
					line2
					line2Url
					showFacebook
					showInstagram
					facebookUrl
					instagramUrl
				}
			}
		}
	`).contentfulFooterComponent

	return (
		<>
			{children}
			<Box id="footer" className={classes.footer}>
				<Box className={classes.logo}>
					<img src={logo.file.url} alt="G-Paints"></img>
				</Box>

				{footerInfoSection
					.filter(
						(section: any) => !(section.showFacebook || section.showInstagram)
					)
					.map(
						(
							section: {
								title: string
								line1: string
								line1Url: string
								line2: string
								line2Url: string
								showFacebook: boolean
								showInstagram: boolean
								facebookUrl: string
								instagramUrl: string
							},
							i: number
						) => (
							<Box key={`footer-info-section-${i}`} className={classes.info}>
								<Typography className={classes.sectionTitle} variant="body1">
									{section.title}
								</Typography>

								<a
									className={classes.sectionBody}
									target="_blank"
									href={section.line1Url}
								>
									<Typography variant="body1" style={{width: '60%'}}>
										{section.line1}
									</Typography>
								</a>
								<a
									className={classes.sectionBody}
									target="_blank"
									href={section.line2Url}
								>
									<Typography variant="body1" style={{width: '60%'}}>
										{section.line2}
									</Typography>
								</a>
							</Box>
						)
					)}

				{footerInfoSection
					.filter(
						(section: any) => section.showFacebook || section.showInstagram
					)
					.map(
						(
							section: {
								title: string
								showFacebook: boolean
								showInstagram: boolean
								facebookUrl: string
								instagramUrl: string
							},
							i: number
						) => (
							<Box
								key={`footer-info-section-social-${i}`}
								className={classes.social}
							>
								<Typography className={classes.sectionTitle} variant="body1">
									{section.title}
								</Typography>

								<Box className={classes.socialIcons}>
									{section.showFacebook && (
										<a
											href={section.facebookUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src="/images/footer/facebook-white.svg"
												alt="Facebook"
											/>
										</a>
									)}
									{section.showInstagram && (
										<a
											href={section.instagramUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src="/images/footer/instagram-white.svg"
												alt="Instagram"
											/>
										</a>
									)}
								</Box>
							</Box>
						)
					)}

				<Box className={classes.copyright}>
					<Typography variant="body1">{footerCopyright}</Typography>
				</Box>
			</Box>
		</>
	)
}

Footer.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default Footer
