import React, {ReactNode} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Typography, TypographyProps} from '@material-ui/core'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * BackdropTypography
 */
interface BackdropTypographyProps extends CommonProps {
	backdropText: string
	className?: string
	id?: string
	children?: ReactNode
	propsToDelegate?: TypographyProps
}

const BackdropTypography: React.FC<BackdropTypographyProps> = ({
	backdropText,
	className,
	id,
	children,
	propsToDelegate
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles({backdropText})

	return (
		<Typography
			{...propsToDelegate}
			id={id}
			className={classNames(classes.backdropTypography, {
				[className]: className
			})}
		>
			{children}
		</Typography>
	)
}

BackdropTypography.propTypes = {
	backdropText: PropTypes.string.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
	children: PropTypes.node,
	propsToDelegate: PropTypes.any
}

export default BackdropTypography
