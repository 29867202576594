import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside ContentFooterWrapper functional template
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		contentFooterWrapper: {
			minHeight: '100vh',
			display: 'grid',
			gridTemplateRows: '1fr auto'
		}
	}))()

export default useStyles
