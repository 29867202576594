import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import {Box, Button} from '@material-ui/core'
import {Link} from 'gatsby'
import {navigate} from 'gatsby-link'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * Header
 */
const Header: React.FC<CommonProps> = ({children}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {
		logo,
		navigationAboutText,
		navigationServicesText,
		navigationReferencesText,
		navigationContactText
	} = useStaticQuery(graphql`
		query contentfulHeaderComponentQuery {
			contentfulHeaderComponent {
				logo {
					file {
						url
					}
				}
				navigationAboutText
				navigationServicesText
				navigationReferencesText
				navigationContactText
			}
		}
	`).contentfulHeaderComponent

	const logoOnClick = () => {
		navigate('/')
	}

	const contactButtonOnClick = () => {
		navigate('/contact')
	}

	return (
		<>
			<Box id="header" className={classes.header}>
				<img
					className={classes.logo}
					src={logo.file.url}
					alt="G-Paints"
					onClick={logoOnClick}
				></img>

				<Box className={classes.navigation}>
					<Link className={classes.navigationItem} to="/#over-ons">
						{navigationAboutText}
					</Link>
					<Link className={classes.navigationItem} to="/#onze-diensten">
						{navigationServicesText}
					</Link>
					<Link className={classes.navigationItem} to="/#onze-realisaties">
						{navigationReferencesText}
					</Link>

					<Button
						className={classes.contactButton}
						variant="outlined"
						color="secondary"
						onClick={contactButtonOnClick}
					>
						{navigationContactText}
					</Button>
				</Box>
			</Box>
			{children}
		</>
	)
}

Header.propTypes = {
	children: PropTypes.node
}

export default Header
