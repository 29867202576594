import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside Footer functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		footer: {
			// Make sure to use ContentFooterWrapper's provided spots
			gridRowStart: 2,
			gridRowEnd: 3,

			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			backgroundColor: theme.palette.common.black,

			// Mobile
			rowGap: theme.spacing(20 / 8),
			columnGap: theme.spacing(15 / 8),
			padding: theme.spacing(48 / 8, 30 / 8, 12 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				rowGap: theme.spacing(10 / 8),
				columnGap: theme.spacing(15 / 8),
				padding: theme.spacing(48 / 8, 75 / 8, 12 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				rowGap: theme.spacing(10 / 8),
				columnGap: theme.spacing(30 / 8),
				padding: theme.spacing(48 / 8, 150 / 8, 12 / 8)
			}
		},
		logo: {
			gridColumn: '1 / span 12',
			gridRow: '1',

			'& img': {
				maxWidth: '100%'
			}
		},
		info: {
			// Starting at 2 since logo is taking spot 1
			'&:nth-child(2)': {
				// Mobile
				gridColumn: '1 / span 12',
				gridRow: '2',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '1 / span 5',
					gridRow: '2'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '5 / span 3',
					gridRow: '1'
				}
			},

			'&:nth-child(3)': {
				// Mobile
				gridColumn: '1 / span 12',
				gridRow: '3',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '6 / span 5',
					gridRow: '2'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '8 / span 3',
					gridRow: '1'
				}
			}
		},
		social: {
			// Mobile
			gridRow: '4',
			gridColumn: '1 / span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridRow: '2',
				gridColumn: '11 / span 2'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridRow: '1',
				gridColumn: '11 / span 2'
			}
		},
		socialIcons: {
			display: 'flex',
			alignItems: 'center',

			// Mobile
			gap: theme.spacing(45 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gap: theme.spacing(24 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gap: theme.spacing(24 / 8)
			}
		},
		copyright: {
			gridColumn: '1 / span 12',
			color: theme.palette.common.white,
			fontFamily: 'Poppins, sans-serif',
			fontSize: 14,
			letterSpacing: '-0.19px',
			lineHeight: '17px',

			// Mobile
			gridRow: '5',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridRow: '3'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridRow: '2'
			}
		},
		sectionTitle: {
			color: theme.palette.common.white,
			fontFamily: 'Poppins',
			fontSize: 18,
			letterSpacing: '-0.25px',
			lineHeight: '22px',
			marginBottom: theme.spacing(8 / 8)
		},
		sectionBody: {
			color: theme.palette.text.secondary,
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '19px',
			textDecoration: 'none',

			'& p': {
				marginBottom: theme.spacing(16 / 8)
			}
		}
	}))()

export default useStyles
