import {common, red} from '@material-ui/core/colors'
import {createMuiTheme} from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

/**
 * Theme definition to provide a global theme which overrides material-ui's default global theme
 * https://material-ui.com/customization/components/#global-theme-override
 */
const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#C91835'
		},
		secondary: {
			main: common.white
		},
		error: {
			main: red.A400
		},
		background: {
			default: '#101116'
		},
		text: {
			primary: common.white,
			secondary: '#7A7A7A'
		}
	},
	typography: {
		h1: {
			fontFamily: ['Poppins', 'sans-serif'].join(','),
			fontWeight: 500,
			letterSpacing: '-0.76px',
			
			// Mobile
			lineHeight: '44px',
			fontSize: 36,

			// Tablet
			[breakpoints.up('sm')]: {
				lineHeight: '67px',
				fontSize: 56
			},
			// Desktop
			[breakpoints.up('lg')]: {
				lineHeight: '67px',
				fontSize: 56
			}
		},
		h2: {
			fontFamily: ['Poppins', 'sans-serif'].join(','),
			fontWeight: 500,
			lineHeight: '67px',
			letterSpacing: '-0.76px',

			// Mobile
			fontSize: 36,

			// Tablet
			[breakpoints.up('sm')]: {
				lineHeight: '67px',
				fontSize: 56
			},
			// Desktop
			[breakpoints.up('lg')]: {
				lineHeight: '67px',
				fontSize: 56
			}
		},
		h3: {
			fontFamily: ['Poppins', 'sans-serif'].join(','),
			lineHeight: '29px',
			letterSpacing: '-0.33px',

			// Mobile
			fontSize: 22,

			// Tablet
			[breakpoints.up('sm')]: {
				fontSize: 24
			},
			// Desktop
			[breakpoints.up('lg')]: {
				fontSize: 24
			}
		},
		h4: {
			fontFamily: ['Poppins', 'sans-serif'].join(',')
		},
		fontFamily: ['Lato', 'sans-serif'].join(',')
	}
})

export default theme
