import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside BackdropTypography functional component
 */

export interface StyleProps {
	backdropText: string
}

const useStyles: Styling.US<StyleProps> = ({backdropText}) =>
	makeStyles((theme: Theme) => ({
		backdropTypography: {
			position: 'relative',

			'&::before': {
				content: `"${backdropText}"`,
				position: 'absolute',
				left: '-20px',
				top: '-50px',
				zIndex: '-1',
				color: '#101116',
				textStroke: '1px rgba(255,255,255,0.1)',
				fontFamily: 'Poppins',
				letterSpacing: '-1.64px',
				lineHeight: '144px',

				// Mobile
				fontSize: 70,

				// Tablet
				[theme.breakpoints.up('sm')]: {
					fontSize: 90
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					fontSize: 120
				}
			}
		}
	}))()

export default useStyles
