import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside Header functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		header: {
			position: 'fixed',
			zIndex: 5,
			top: 0,
			left: 0,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: 'rgba(16, 17, 22, 0.8)',
			backdropFilter: 'blur(10px)',

			// Mobile
			padding: theme.spacing(30 / 8, 30 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(30 / 8, 48 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				padding: theme.spacing(30 / 8, 48 / 8)
			}
		},
		logo: {
			maxWidth: '60%',
			cursor: 'pointer'
		},
		navigation: {
			display: 'flex',
			gap: theme.spacing(48 / 8),
			justifyContent: 'flex-end',
			alignItems: 'center'
		},
		navigationItem: {
			position: 'relative',
			color: theme.palette.text.primary,
			fontFamily: 'Poppins, sans-serif',
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '19px',
			textDecoration: 'none',

			transition: 'color 0.5s',
			transitionTimingFunction: 'cubic-bezier(0.2,1,0.3,1)',

			'&:nth-child(odd)': {
				'&:before': {
					background: '#fe628e'
				},
				'&:hover': {
					color: '#fe628e'
				}
			},
			'&:nth-child(even)': {
				'&:before': {
					background: '#6265fe'
				},
				'&:hover': {
					color: '#6265fe'
				}
			},

			'&:before': {
				content: '""',
				position: 'absolute',
				zIndex: '-1',
				width: '100%',
				height: '50%',
				left: 0,
				bottom: 0,
				opacity: 0.3,
				transform: 'scale3d(0,1,1)',
				transformOrigin: '0% 50%',
				transition: 'transform 0.5s',
				transitionTimingFunction: 'cubic-bezier(0.2,1,0.3,1)'
			},
			'&:hover': {
				'&:before': {
					transform: 'scale3d(1,1,1)'
				}
			},

			// Mobile
			display: 'none',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				display: 'block'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				display: 'block'
			}
		},
		contactButton: {
			borderRadius: 28,
			textTransform: 'none',

			// Mobile
			padding: theme.spacing(16 / 8, 25 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(16 / 8, 40 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				padding: theme.spacing(16 / 8, 40 / 8)
			}
		}
	}))()

export default useStyles
