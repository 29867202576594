import * as React from 'react'
import * as PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'

import MuiThemeWrapper from '@styles/mui-theme-wrapper'
import {ContentFooterWrapper, I18nWrapper} from '@wrappers/index'
import {CommonPageContext} from '@utils/types'
import {FooterComponent, HeaderComponent} from '@components/index'

import './top-layout.scss'

/**
 * TopLayout
 */
interface TopLayoutProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	pageContext: CommonPageContext
}

export const TopLayout: React.FC<TopLayoutProps> = ({
	children,
	pageContext
}) => (
	<React.Fragment>
		<Helmet>
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@300;400;500;600;700&display=swap"
				rel="stylesheet"
			/>
			<link href="/lightbox/dist/css/lightbox.min.css" rel="stylesheet" />
		</Helmet>
		<MuiThemeWrapper>
			<I18nWrapper languageFromPath={pageContext.pathLanguageIso}>
				<ContentFooterWrapper>
					<HeaderComponent>
						<FooterComponent>{children}</FooterComponent>
					</HeaderComponent>
				</ContentFooterWrapper>
			</I18nWrapper>
		</MuiThemeWrapper>
	</React.Fragment>
)

TopLayout.propTypes = {
	children: PropTypes.node,
	pageContext: PropTypes.any.isRequired
}

export default TopLayout
