import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import locales from './locales'

export const DEFAULT_LANGUAGE = 'en'

i18n.use(initReactI18next).init({
	fallbackLng: DEFAULT_LANGUAGE,
	resources: {
		...locales
	},
	ns: ['translations'],
	defaultNS: 'translations',
	returnObjects: true,
	debug: process.env.NODE_ENV === 'development',
	interpolation: {
		escapeValue: false
	}
})

export default i18n
