import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside Margin functional component
 */

export interface StyleProps {
	top?: number
	right?: number
	bottom?: number
	left?: number
}

const useStyles: Styling.US<StyleProps> = ({top, right, bottom, left}) => {
	const marginTop = top ? top / 8 : 0
	const marginRight = right ? right / 8 : 0
	const marginBottom = bottom ? bottom / 8 : 0
	const marginLeft = left ? left / 8 : 0

	return makeStyles((theme: Theme) => ({
		root: {
			height: 0,
			margin: theme.spacing(marginTop, marginRight, marginBottom, marginLeft)
		}
	}))()
}

export default useStyles
