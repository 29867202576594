import React from 'react'

import useStyles from './style.hook'

/**
 * ContentFooterWrapper
 */
const ContentFooterWrapper: React.FC<{children: React.ReactNode}> = ({
	children
}) => {
	// Get styles from template-scoped styles hook
	const classes = useStyles()

	return (
		<div id="contentFooterWrapper" className={classes.contentFooterWrapper}>
			{children}
		</div>
	)
}

export default ContentFooterWrapper
