import EN from '@i18n/locales/en'
import NL from '@i18n/locales/nl'
import FR from '@i18n/locales/fr'

export default {
	en: {
		translations: {
			...EN
		}
	},
	nl: {
		translations: {
			...NL
		}
	},
	fr: {
		translations: {
			...FR
		}
	}
}
