import React from 'react'
import classNames from 'classnames'
import {Button, ButtonProps} from '@material-ui/core'

import useStyles from './style.hook'

/**
 * GlowButton
 */
const GlowButton: React.FC<ButtonProps> = props => {
	// Get styles from component-scoped styles hook
	const classes = useStyles({color: props.color})

	return (
		<Button
			{...props}
			className={classNames(classes.glowButton, {
				[props.className]: props.className
			})}
		/>
	)
}

export default GlowButton
